.gradient-container-ht {
  position: absolute; /* Ensures it covers the entire hero section */
  top: 0;
  left: 0;
  width: 100%;
  min-height: 500px;
  overflow: hidden; /* Hide overflow to keep it neat */
  background-color: #06090A; /* Base color */
}

.gradient-layer-ht {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(100px); /* Apply a blur effect to the gradients */
  opacity: 0.8; /* Slight opacity for better blending */
}

.gradient-layer-ht-1 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-ht-2 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-ht-3 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-ht-4 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-ht-inv {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(100px); /* Apply a blur effect to the gradients */
  opacity: 0.8; /* Slight opacity for better blending */
}

.gradient-layer-ht-1-inv {
  background: radial-gradient(circle at 20% 30%, #F86E5E, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-ht-2-inv {
  background: radial-gradient(circle at 80% 40%,  #001A23, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-ht-3-inv {
  background: radial-gradient(circle at 50% 70%, #1ACBC0, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-ht-4-inv {
  background: radial-gradient(ellipse at 70% 20%, #d9d7dd, transparent 70%);
  top: 30%;
  left: 60%;
}