.gradient-container {
  position: absolute; /* Ensures it covers the entire hero section */
  top: 0;
  left: 0;
  width: 100%;
  min-height: 1200px;
  overflow: hidden; /* Hide overflow to keep it neat */
  background-color: #ffffff; /* Base color */
}

.gradient-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(100px); /* Apply a blur effect to the gradients */
  opacity: 0.8; /* Slight opacity for better blending */
}

.gradient-layer-1 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-2 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-3 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 60%;
  left: 20%;
}

.gradient-layer-4 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 50%;
  left: 70%;
}

.gradient-layer-5 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-6 {
  background: radial-gradient(circle at 80% 40%, #1ACBC0, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-7 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-8 {
  background: radial-gradient(ellipse at 70% 20%, #d9d7dd, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-9 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-10 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-11 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-12 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-13 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-14 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-15 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-16 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


@media (max-width: 992px) {
 
.gradient-layer-1 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-2 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-3 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-4 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}

.gradient-layer-5 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-6 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-7 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-8 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-9 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-10 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-11 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-12 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-13 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-14 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-15 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-16 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


}

@media (max-width: 768px) {
 
.gradient-layer-1 {
  background: radial-gradient(circle at 40% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-2 {
  background: radial-gradient(circle at 90% 20%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-3 {
  background: radial-gradient(circle at 60% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-4 {
  background: radial-gradient(ellipse at 80% 10%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}

.gradient-layer-5 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-6 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-7 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-8 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-9 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-10 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-11 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-12 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


.gradient-layer-13 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-14 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-15 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-16 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}


}
