.gradient-container-ft {
  position: absolute; /* Ensures it covers the entire hero section */
  top: 0;
  left: 0;
  width: 100%;
  min-height: 500px;
  overflow: hidden; /* Hide overflow to keep it neat */
  background-color: #ffffff; /* Base color */
}

.gradient-layer-ft {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(100px); /* Apply a blur effect to the gradients */
  opacity: 0.8; /* Slight opacity for better blending */
}

.gradient-layer-ft-1 {
  background: radial-gradient(circle at 20% 30%, #d9d7dd, transparent 70%);
  top: -10%;
  left: -10%;
}

.gradient-layer-ft-2 {
  background: radial-gradient(circle at 80% 40%, #F86E5E, transparent 70%);
  top: -20%;
  left: 20%;
}

.gradient-layer-ft-3 {
  background: radial-gradient(circle at 50% 70%, #001A23, transparent 70%);
  top: 0%;
  left: 50%;
}

.gradient-layer-ft-4 {
  background: radial-gradient(ellipse at 70% 20%, #1ACBC0, transparent 70%);
  top: 30%;
  left: 60%;
}